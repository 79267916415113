import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import Api from './api/index';
import Service from './service/index';
import BaiduMap from 'vue-baidu-map'
import '@/assets/style/reset.less';
import '@/assets/style/main.less';
// import '@/assets/style/media.less';
import 'element-ui/lib/theme-chalk/display.css';

import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css/animate.min.css';
import "swiper/swiper-bundle.min.css";
import './util/flexible';
let charts = require('echarts')
// import charts from  //引入echarts
Vue.prototype.$echarts = charts //引入组件
import lazyload from "vue-lazyload"
Vue.use(lazyload)
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Api);
Vue.use(Service);
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'YOUR_APP_KEY'
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
