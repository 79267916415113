import axios from 'axios';
// import qs from 'qs';
import store from '@/store/index';
import router from '@/router/index';
import { Message } from 'element-ui';

axios.defaults.timeout = 10000;
// axios.defaults.baseURL = 'http://192.168.6.40:12323/';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//http请求拦截
axios.interceptors.request.use(function(config) {
    // Do something before request is sent
    const token = store.state.authToken; //这里取token之前，你肯定需要先拿到token,存一下
    if (token) {
        // config.params = { 'token': token } //如果要求携带在参数中
        config.headers['x-auth-token'] = token; //如果要求携带在请求头中
    }
    return config;
}, function(error) {
    // Do something with request error
    return Promise.reject(error);
});
// http响应拦截
axios.interceptors.response.use(function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status == '200') {
        return response.data;
    } else {
        console.log('请求失败');
    }

}, function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
        switch (error.response.status) {
            case 401:
                Message({
                    type: 'warning',
                    message: "验证码失效,请重试"
                });
                setTimeout(() => {
                    location.reload();
                }, 800);
                break;
            case 403:
                console.log(403)
                Message({
                    type: 'warning',
                    message: "登录已失效，请重新登录"
                });
                store.commit('CLEAR_LOGIN');
                setTimeout(() => {
                    router.push({ name: 'login' });
                }, 1500);
                break;
            case 404:
                console.log(404)
                break;
            case 500:
                console.log(500)
                break;
        }
    }
    return Promise.reject(error);
});
class Http {
    constructor() {}
    get(url, params = {}, responseType = "application/json", proxy = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, { params, responseType, proxy, }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err)
            });
        })
    }
    post(url, data = {}, headers = { 'Content-Type': "application/json" }, proxy = {}) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'POST',
                    proxy,
                    headers,
                    data,
                    url,
                })
                .then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                });
        })
    }
    delete(url, data = {}, headers = { 'Content-Type': "application/json" }, proxy = {}) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'delete',
                    proxy,
                    headers,
                    data,
                    url,
                })
                .then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                });
        })
    }
}
export default Http;