<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="less">
@import "./assets/style/common/common.css";
html,
body {
  min-width: 320px;
}
#app {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // overflow:auto;
  /*overflow: auto;*/
  height: 100%;
  width: 100%;
}
.BMap_pop {
  background-color: #2b6bd5 !important;
}

/*!* 约束屏幕尺寸 *!*/
@media screen and (max-width: 768px) {
  html {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 80px !important;
  }
}
</style>
