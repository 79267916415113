import Vue from 'vue';
import common from './common';

// import { Toast } from 'vant';

const SERVICE_LIST = {
    // 通用模块
    COMMON_SERVICE: {...common },

}
class Service {
    constructor() {
        for (let namespace_key of Object.keys(SERVICE_LIST)) {
            let serviceItemList = SERVICE_LIST[namespace_key];
            if (Reflect.has(this, namespace_key)) {
                alert('service键值重复，请检查');
                return false;
            }
            this[namespace_key] = {};
            for (let key of Object.keys(serviceItemList)) {
                if (Reflect.has(this[namespace_key], key)) {
                    alert(`service${namespace_key}目录下${key}键值重复，请检查`);
                    return false;
                }
                let { method, url, responseType, proxy: setProxy, params: defaultParams } = serviceItemList[key];
                this[namespace_key][key] = (params = {}, resType = responseType, proxy = setProxy) => {
                    return new Promise((resolve, reject) => {
                        Vue.api[method](url, {...defaultParams, ...params }, resType, proxy).then((res) => {
                            resolve(res);
                        }).catch((err) => {
                            reject(err);
                        })
                    })
                }
            }
        }
    }
}
export default {
    install(Vue) {
        Vue.service = Vue.prototype.service = new Service();
    }
}