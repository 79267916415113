import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'layout',
  redirect: '/home',
  component: () =>
    import('@/views/Layout.vue'),
  children: [{
    path: '/home',
    name: 'home',
    component: () =>
      import('@/views/Home.vue')
  }, {
    path: '/about',
    name: 'about',
    component: () =>
      import('@/views/About.vue')
  }, {
    path: '/product',
    name: 'product',
    component: () =>
      import('@/views/Product.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () =>
      import('@/views/News.vue')
  },
  {
    path: '/customer',
    name: 'customer',
    component: () =>
      import('@/views/Customer.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    component: () =>
      import('@/views/Solution.vue')
  },
  {
    path: '/shanhai',
    name: 'shanhai',
    component: () =>
      import('@/views/Shanhai.vue')
  },
  ]
}, {
  path: '/applyPage',
  name: 'applyPage',
  component: () =>
    import('@/views/ApplyPage.vue')
}, {
  path: '*',
  name: 'notFound',
  component: () =>
    import('@/views/NotFound.vue')
}]

const router = new VueRouter({
  routes
})

export default router