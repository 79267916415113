import Http from './http';
class Api extends Http {
    constructor() {
        super()
    }
}
export default {
    install(Vue) {
        Vue.api = Vue.prototype.api = new Api();
    }
}